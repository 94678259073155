<template>
  <!-- NAME[epic=动销] 预存款汇总统计 -->
  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 250px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="deposit_id">
        <el-select
          v-model="form.deposit_id"
          clearable
          style="width: 140px"
          placeholder="请选择活动"
        >
          <el-option
            v-for="(i, idx) in activeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.is_left"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="请选择业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          style="width: 140px"
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="' selectDC'"
          style="width: 140px"
          @select-id="selectCust"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="note">
        <el-input
          v-model="form.note"
          placeholder="备注"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
        <template #default="{ row, $index }">
          <div v-if="row.bill_code == '合计' || row.bill_code == '总计'"></div>
          <div v-else>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getSummaryList, activeSelect } from '@/api/preOrderDetail'
  // 业务员下拉
  import { getStaffList } from '@/api/setPrice'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: 'PreSummary',
    components: {
      GoodsSearch,
      ClientSearch,
    },
    data() {
      return {
        loading: false,
        time: [],
        activeList: [],
        staffList: [],
        //收款情况1未结清2已结清3已终止
        statusList: [
          {
            id: 1,
            name: '未结清',
          },
          {
            id: 2,
            name: '已结清',
          },
          {
            id: 3,
            name: '已终止',
          },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          pageNo: 1, //页数
          pageSize: 10, //条数
          start_time: '', //开始时间
          end_time: '', //结束时间
          deposit_id: '', //活动id,业务员自建是-1
          staff_id: '', //业务员id
          goods_id: '', //商品id
          cust_id: '', //客户id
          bill_code: '', //预存款单据号
          sort: '', //排序类型
          order: '', //序方式desc降序asc升序,多个以,隔开

          is_left: '', //收款情况1未结清2已结清3已终止
          note: '', //备注
        },
        list: [],
        checkList: [
          '客户名称',
          '单号',
          '活动名称',
          '签单日期',
          '业务员',
          '签单金额',
          '累计收款',
          '赠送金额',
          '待收款金额',
          '使用金额',
          '退货金额',
          '可用余额',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 3,
            label: '活动名称',
            prop: 'name',
            width: '',
          },
          {
            order: 4,
            label: '签单日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 5,
            label: '业务员',
            prop: 'staff_user',
            width: '',
          },
          {
            order: 6,
            label: '签单金额',
            prop: 'total_amount',
            width: '',
          },
          {
            order: 7,
            label: '累计收款',
            prop: 'receive_amount',
            width: '',
          },
          {
            order: 8,
            label: '赠送金额',
            prop: 'gift_amount',
            width: '',
          },
          {
            order: 9,
            label: '待收款金额',
            prop: 'no_amount',
            width: '',
          },
          {
            order: 10,
            label: '使用金额',
            prop: 'already_amount',
            width: '',
          },
          {
            order: 11,
            label: '退货金额',
            prop: 'return_amount',
            width: '',
          },
          {
            order: 12,
            label: '可用余额',
            prop: 'able_amount',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 活动下拉
      activeSelect({ pageSize: -1 }).then((res) => {
        this.activeList = res.data
        this.activeList.push({
          id: -1,
          name: '业务员创建',
        })
      })
      // 业务员下拉
      getStaffList().then((res) => {
        this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getSummaryList(this.form)
        this.list = data.list
        this.total = totalCount
        this.loading = false
      },
      selectGoods(val) {
        this.form.goods_id = val.goods_id
      },
      selectCust(val) {
        this.form.cust_id = val
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.form.goods_id = ''
        this.form.cust_id = ''
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
        this.time = []
      },
      handleExport() {
        downloadFile(
          '/promoteAdmin/deposit-order/total-export',
          '预存款汇总统计.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
